import React from 'react'
import { Route, Switch, useParams } from 'react-router-dom'
import { url2section } from 'ion-sections'
import loadable from '@loadable/component'
import NewspaperButton from '../../../assets/images/buttons/independentonsaturday-button.png'
import { DefaultSectionPageSkeletonWrapper, ArticlePageSkeleton } from '../components/Skeletons'

const TitleSectionPage = loadable(() => import('../pages/TitleSectionPage'), {
  fallback: <DefaultSectionPageSkeletonWrapper />
})
const TagPage  = loadable(() => import('../pages/TagPage'))
const Article = loadable(() => import('../pages/Article'), {
  fallback: <ArticlePageSkeleton />
})

const RenderArticle = (props) => {
  const { contentKey } = useParams()
  return <Article {...props} contentKey={contentKey} />
}

const IndependentOnSaturday = (props) => (
  <Switch>
    <Route path='/ios' exact render={() => (
      <TitleSectionPage {...props}
        section='ios'
        sectionLabel='Independent on Saturday'
        relatedSections={[{ id: 'ios/news' }, { id: 'ios/world' }, { id: 'ios/sport' }, { id: 'ios/opinion' }, { id: 'ios/arts' }, { id: 'ios/behindthenews', label: 'Behind The News' }]}
        title='Independent on Saturday'
        description='-'
        publication='Independent on Saturday'
        newspaperTitle='The Independent on Saturday on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://independentonsaturday.zinioapps.com/shop'
      />
    )} />
    <Route path='/ios/news' exact render={() => (
      <TitleSectionPage {...props}
        section='ios/news'
        relatedSections={[{ id: 'ios', label: 'Independent on Saturday' }, { id: 'ios/world' }, { id: 'ios/sport' }, { id: 'ios/opinion' }, { id: 'ios/arts' }, { id: 'ios/behindthenews', label: 'Behind The News' }]}
        title='Independent on Saturday | News'
        description='-'
        publication='Independent on Saturday'
        newspaperTitle='The Independent on Saturday on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://independentonsaturday.zinioapps.com/shop'
      />
    )} />
    <Route path='/ios/world' exact render={() => (
      <TitleSectionPage {...props}
        section='ios/world'
        relatedSections={[{ id: 'ios', label: 'Independent on Saturday' }, { id: 'ios/news' }, { id: 'ios/sport' }, { id: 'ios/opinion' }, { id: 'ios/arts' }, { id: 'ios/behindthenews', label: 'Behind The News' }]}
        title='Independent on Saturday | World'
        description='-'
        publication='Independent on Saturday'
        newspaperTitle='The Independent on Saturday on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://independentonsaturday.zinioapps.com/shop'
      />
    )} />
    <Route path='/ios/sport' exact render={() => (
      <TitleSectionPage {...props}
        section='ios/sport'
        relatedSections={[{ id: 'ios', label: 'Independent on Saturday' }, { id: 'ios/news' }, { id: 'ios/world' }, { id: 'ios/opinion' }, { id: 'ios/arts' }, { id: 'ios/behindthenews', label: 'Behind The News' }]}
        title='Independent on Saturday | Sport'
        description='-'
        publication='Independent on Saturday'
        newspaperTitle='The Independent on Saturday on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://independentonsaturday.zinioapps.com/shop'
      />
    )} />
    <Route path='/ios/opinion' exact render={() => (
      <TitleSectionPage {...props}
        section='ios/opinion'
        relatedSections={[{ id: 'ios', label: 'Independent on Saturday' }, { id: 'ios/news' }, { id: 'ios/world' }, { id: 'ios/sport' }, { id: 'ios/arts' }, { id: 'ios/behindthenews', label: 'Behind The News' }]}
        title='Independent on Saturday | Opinion'
        description='-'
        publication='Independent on Saturday'
        newspaperTitle='The Independent on Saturday on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://independentonsaturday.zinioapps.com/shop'
      />
    )} />
    <Route path='/ios/arts' exact render={() => (
      <TitleSectionPage {...props}
        section='ios/arts'
        relatedSections={[{ id: 'ios', label: 'Independent on Saturday' }, { id: 'ios/news' }, { id: 'ios/world' }, { id: 'ios/sport' }, { id: 'ios/opinion' }, { id: 'ios/behindthenews', label: 'Behind The News' }]}
        title='Independent on Saturday | Arts'
        description='-'
        publication='Independent on Saturday'
        newspaperTitle='The Independent on Saturday on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://independentonsaturday.zinioapps.com/shop'
      />
    )} />
    <Route path='/ios/behindthenews' exact render={() => (
      <TitleSectionPage {...props}
        section='ios/behindthenews'
        relatedSections={[{ id: 'ios', label: 'Independent on Saturday' }, { id: 'ios/news' }, { id: 'ios/world' }, { id: 'ios/sport' }, { id: 'ios/opinion' }, { id: 'ios/arts' }]}
        title='Independent on Saturday | Behind The News'
        description='-'
        publication='Independent on Saturday'
        newspaperTitle='The Independent on Saturday on zinioapps.com'
        newspaperButton={NewspaperButton}
        newspaperUrl='https://independentonsaturday.zinioapps.com/shop'
      />
    )} />
    <Route path={'/ios/:section([a-zA-Z0-9-/]+)/:title*([-])*:contentKey([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})'} exact render={() => (
      <RenderArticle {...props} oovvuu='4c0314aa-87fa-4a57-8152-521cc269c23c' />
    )} />
    <Route path={'/ios/:title*([-])*:contentKey([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})'} exact render={() => (
      <RenderArticle {...props} oovvuu='4c0314aa-87fa-4a57-8152-521cc269c23c' />
    )} />
    <Route path={'/ios/:section([a-zA-Z0-9-/]+)/:title*([-])*:contentKey([0-9]{5,})'} exact component={RenderArticle} />
    <Route path={'/ios/:title*([-])*:contentKey([0-9]{5,})'} exact component={RenderArticle} />
    <Route path={'/ios/:section([a-zA-Z0-9-/]+)'} exact render={() => (
      <TagPage {...props}
        section={url2section(props.location.pathname)}
        url={props.location.pathname}
      />
    )} />
  </Switch>
)

export default IndependentOnSaturday
